.top-bar2 {
    height       : 40px;
    border-bottom: 1px solid #eeeeee;

    .bar-left {
        margin-top: 8px;

        ul {
            li {
                font-size   : 14px;
                color       : #666666;
                margin-right: 20px;
                display     : inline-block;

                i {
                    font-size     : 18px;
                    color         : #11B67A;
                    vertical-align: text-bottom;
                    margin-right  : 5px;

                    @media(max-width: 1199px) {
                        margin-right : 2px;
                    }
                }

                @media(max-width: 1199px) {
                    margin-right : 8px;
                }

                @media(max-width: 991px) {
                    font-size : 13px;
                }
            }
        }
    }

    .bar-right {
        margin-top: 6px;

        ul.bar-lang {
            margin-right: 30px;
            position    : relative;
            margin-top  : 3px;

            &::before {
                position  : absolute;
                content   : "";
                background: #eeeeee;
                width     : 1px;
                height    : 20px;
                top       : 0;
                right     : -15px;
            }

            li {
                .dropdown {
                    button.dropdown-toggle {
                        font-size : 13px;
                        color     : #666666;
                        background: transparent;
                        border    : none;
                        padding   : 0;
                        box-shadow: none;

                        img {
                            margin-right: 5px;
                            max-width   : 21px;
                        }

                        i {
                            font-size  : 12px;
                            margin-left: 3px;
                        }

                        &::after {
                            display: none;
                        }

                        &:hover {
                            cursor: pointer;
                            color : #11B67A;
                        }
                    }

                    ul.dropdown-menu {
                        padding         : 0;
                        margin          : 0;
                        border          : none;
                        background-color: #ffffff;
                        border-radius : 5px;
                        top       : 50% !important;
                        left      : -16px !important;
                        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

                        li {
                            display      : block;
                            border-bottom: 1px solid #eeeeee;
                            padding      : 7px 15px;
                            color        : #969696;
                            cursor       : pointer;

                            img {
                                max-width   : 21px;
                                margin-right: 5px;
                            }

                            &:hover {
                                color           : #182B49;
                                font-weight     : 500;
                                background-color: transparent;
                            }

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        ul.bar-social {
            margin-right: 30px;
            position    : relative;

            &::before {
                position  : absolute;
                content   : '';
                background: #eeeeee;
                width     : 1px;
                height    : 20px;
                top       : 3px;
                right     : -15px;
            }

            li {
                a {
                    font-size  : 13px;
                    color      : #11B67A;
                    background : #eeeeee;
                    display    : inline-block;
                    width      : 25px;
                    height     : 25px;
                    text-align : center;
                    padding-top: 3px;
                    border-radius : 5px;

                    &:hover {
                        background: #11B67A;
                        color     : #ffffff;
                    }
                }

                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }

            @media(max-width: 991px) {
                display: none;
            }
        }

        ul.sidebar-button {
            li.side-box {
                a.nav-link {
                    font-size  : 20px;
                    padding    : 0;
                    line-height: 28px;
                    i {
                        color : #11B67A;
                    }
                }
            }
        }
    }
    
    @media(max-width: 767px) {
        display: none;
    }
}

.logo-area2 {
    height     : 82px;
    padding-top: 20px;

    .logo {
        a {
            img {
                @media(max-width: 991px) {
                    max-width: 100%;
                }
            }
        }
    }

    div.menu-box {
        ul.nav.menu-nav {
            li.nav-item {
                position: relative;

                a.nav-link {
                    font-size     : 14px;
                    color         : #182B49;
                    text-transform: uppercase;
                    font-weight   : 500;
                    padding       : 10px 10px 20px;

                    i {
                        font-size: 12px;
                    }

                    &:after {
                        content: none;
                    }

                    &:hover {
                        color: #11B67A;
                    }

                    @media(max-width: 1199px) {
                        padding: 10px 2px 20px;
                        letter-spacing: 0;
                    }
                }

                ul.dropdown {
                    position  : absolute;
                    left      : 0;
                    top       : 100%;
                    min-width : 190px;
                    background: #fff;
                    border    : 1px solid #eeeeee;
                    text-align: left;
                    padding   : 0;
                    border-radius : 5px;
                    transition : 0.2s ease;
                    opacity         : 0;
                    transform       : scaleY(0);
                    visibility      : hidden;
                    z-index         : 999;
                    transform-origin: center top 0;

                    li.nav-item {
                        position: relative;

                        a.nav-link {
                            font-size     : 13px;
                            color         : #555555;
                            padding       : 10px 20px;
                            text-transform: capitalize;
                            font-weight   : 400;
                            margin-right  : 0;
                            border-bottom : 1px solid #eeeeee;

                            &:hover {
                                color: #11B67A;

                                i {
                                    color: #fff;
                                }
                            }

                            i {
                                float     : right;
                                margin-top: 4px;
                            }
                        }

                        &:last-child {
                            margin-left: 0;

                            a.nav-link {
                                border-bottom: none;
                            }
                        }

                        ul.dropdown2 {
                            position  : absolute;
                            top       : 0;
                            left      : 100%;
                            min-width : 180px;
                            background: #fff;
                            border    : 1px solid #eeeeee;
                            transition : 0.2s ease;
                            opacity         : 0;
                            transform       : scaleY(0);
                            visibility      : hidden;
                            z-index         : 999;
                            transform-origin: center top 0;
                        }

                        &:hover {
                            ul.dropdown2 {
                                opacity   : 1;
                                transform : scaleY(1);
                                visibility: visible;
                            }
                        }
                    }
                }

                &:hover {
                    ul.dropdown {
                        opacity   : 1;
                        transform : scaleY(1);
                        visibility: visible;
                    }
                }
            }
        }

        .search-box {
            margin-left: 18px;
            margin-top : 11px;

            a {
                padding: 0;

                i {
                    font-size: 18px;
                    color    : #11B67A;
                }
            }

            i.close-btn {
                position : absolute;
                top      : 25px;
                right    : -12px;
                font-size: 26px;
                color    : #11B67A;
                cursor   : pointer;
            }

            @media(max-width: 1199px) {
                margin-left : 5px;
            }
        }

        .apply-btn {
            margin-left: 70px;
            margin-top : -1px;

            a {
                font-size     : 13px;
                color         : #ffffff;
                background    : linear-gradient(90deg, #125b8b 0%, #125b8b 100%);
                display       : inline-block;
                width         : 110px;
                height        : 40px;
                text-align    : center;
                text-transform: uppercase;
                font-weight   : 500;
                padding       : 11px;
                border-radius : 0 5px 5px 0;
                position: relative;

                i {
                    position : absolute;
                    font-size: 20px;
                    left     : -40px;
                    top      : 0;
                    padding  : 10px;
                    width    : 40px;
                    height   : 100%;
                    border-radius : 5px 0 0 5px;
                    background-color: #f1933d;
                }

                &:hover {
                    background: linear-gradient(90deg, #009444 0%, #11B67A 100%);
                }
            }

            @media(max-width: 991px) {
                display : none;
            }
        }
    }

    @media(max-width: 767px) {
        display: none;
    }
}