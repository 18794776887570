.course-category {
    border : 1px solid #eeeeee;
    padding: 15px 20px 20px;
    border-radius : 5px;
    margin-bottom : 30px;
    h5 {
        color : #182B49;
        font-weight : 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position : relative;
        &:before {
            position : absolute;
            content : "";
            background : #11B67A;
            width : 50px;
            height: 2px;
            bottom : 0;
            left : 0;
        }

        @media(max-width: 575px) {
            font-size  : 17px;
        }
    }
    ul.category-item {
        li.check-btn {
            border-top : 1px dashed #dddddd;
            padding: 10px 0;

            &:last-child {
                border-bottom : 1px dashed #dddddd;
            }

            label {
                font-size: 14px;
                color: #969696;
                display: block;
                margin-bottom : 4px;
                cursor: pointer;

                input[type=checkbox] {
                    border : 2px solid #dddddd;
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    margin-right: 6px;
                    position: relative;
                    top: 4px;

                    &:focus {
                        outline: none;
                    }

                    &:checked {
                        background-color: #11B67A;
                        background: #11B67A url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                        border-color : #11B67A;
                    }
                }

                span {
                    float : right;
                    font-size: 12px;
                    color: #666666;
                    line-height: 27px;
                }
            }
        }
    }

    @media(max-width: 1199px) {
        padding: 12px 15px 15px;
    }
}