.login-page {
    .login-area {
        padding : 70px 0;
        .login-box {
            max-width : 500px;
            margin: auto;
            border: 1px solid #eeeeee;
            box-shadow: 0 0px 20px rgba(0,0,0,0.08);
            padding: 25px 30px 35px;
            border-radius: 5px;
            .login-title {
                h3 {
                    color : #444444;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: #11B67A;
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 50%;
                        margin-left: -25px;
                    }

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }

            form.form {
                p.form-control {
                    padding      : 0;
                    width        : auto;
                    height       : auto;
                    background   : transparent;
                    border       : none;
                    margin-bottom: 28px;
                    position     : relative;

                    label {
                        font-size : 15px;
                        color : #555555;
                        font-weight : 500;

                        @media(max-width: 575px) {
                            font-size : 14px;
                        }
                    }

                    input {
                        width           : 100%;
                        height          : 48px;
                        background-color: #ffffff;
                        font-size       : 14px;
                        padding         : 15px 20px;
                        color           : #182B49;
                        border          : 1px solid #dddddd;
                        border-radius : 5px;

                        &::placeholder {
                            font-size : 14px;
                            font-style: italic;
                            color     : #969696;

                            @media(max-width: 575px) {
                                font-size : 13px;
                            }
                        }

                        &:focus {
                            border-color : #11B67A;
                        }

                        @media(max-width: 575px) {
                            height : 40px;
                        }
                    }

                    span {
                        color      : #ff6f6f;
                        font-weight: 300;
                        position   : absolute;
                        bottom     : -20px;
                        left       : 0;
                        visibility : hidden;
                    }
                }

                p.form-control.success {
                    input {
                        border: 2px solid #11B67A;
                    }

                    &::before {
                        position   : absolute;
                        content    : "\f058";
                        font-family: "Line Awesome Free";
                        font-size  : 24px;
                        color      : #11B67A;
                        font-weight: 900;
                        top        : 34px;
                        right      : 10px;
                    }
                }

                p.form-control.error {
                    input {
                        border: 2px solid #ff6f6f;
                    }

                    &::before {
                        position   : absolute;
                        content    : "\f06a";
                        font-family: "Line Awesome Free";
                        font-size  : 24px;
                        color      : #ff6f6f;
                        font-weight: 900;
                        top        : 34px;
                        right      : 10px;
                    }
                }

                p.form-control.error {
                    span {
                        visibility: visible;
                    }
                }

                button {
                    font-size  : 16px;
                    color      : #fff;
                    background : linear-gradient(90deg, #11B67A 0%, #009444 100%);
                    width      : 100%;
                    height     : 48px;
                    font-weight: 500;
                    border     : none;
                    border-radius : 5px;
                    text-transform: uppercase;
                    margin-bottom : 20px;

                    &:hover {
                        background: linear-gradient(90deg, #009444 0%, #11B67A 100%);

                        i {
                            color: #ffffff;
                        }
                    }

                    @media(max-width: 575px) {
                        font-size : 15px;
                        height     : 40px;
                    }
                }

                .save-forget-password {
                    margin-bottom: 15px;
                    .save-passowrd {
                        label {
                            font-size: 14px;
                            color: #969696;
                            display: block;
                            font-weight : 500;
                            margin-bottom : 0;
                            cursor: pointer;

                            input[type=checkbox] {
                                border : 2px solid #dddddd;
                                appearance: none;
                                width: 18px;
                                height: 18px;
                                cursor: pointer;
                                margin-right: 6px;
                                position: relative;
                                top: 4px;

                                &:focus {
                                    outline: none;
                                }

                                &:checked {
                                    background-color: #11B67A;
                                    background: #11B67A url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                                    border-color : #11B67A;
                                }
                            }
                        }
                    }
                    .forget-password {
                        margin-top: 3px;
                        a {
                            font-size : 14px;
                            color : #11B67A;
                            font-weight : 500;
                            &:hover {
                                text-decoration : underline;
                            }
                        }
                    }
                }

                .not_account-btn {
                    border-bottom: 1px solid #eeeeee;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    p {
                        font-size : 14px;
                        color     : #969696;
                        a {
                            font-size : 14px;
                            color : #11B67A;
                            font-weight : 500;
                            &:hover {
                                text-decoration : underline;
                            }
                        }
                    }
                }

                .social-login {
                    p {
                        font-size : 14px;
                        color     : #969696;
                        margin-bottom : 15px;
                    }
                    ul {
                        li {
                            a {
                                font-size: 14px;
                                color: #ffffff;
                                display: inline-block;
                                width: 110px;
                                height: 40px;
                                border-radius: 30px;
                                padding-top: 10px;
                                i {
                                    margin-right : 3px;
                                }
                                &:hover {
                                    background: #11B67A !important;
                                }

                            }
                            &:nth-child(1) {
                                a {
                                    background: #DB4437;
                                }
                            }
                            &:nth-child(2) {
                                a {
                                    background: #4267B2;
                                }
                            }
                            &:nth-child(3) {
                                a {
                                    background: #1DA1F2;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0;
        }
    }
}

.registration-page {
    .registration-area {
        padding : 70px 0;
        .registration-box {
            max-width : 500px;
            margin: auto;
            border: 1px solid #eeeeee;
            box-shadow: 0 0px 20px rgba(0,0,0,0.08);
            padding: 25px 30px;
            border-radius: 5px;
            .registration-title {
                h3 {
                    color : #444444;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: #11B67A;
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 50%;
                        margin-left: -25px;
                    }

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }

            form.form {
                p.form-control {
                    padding      : 0;
                    width        : auto;
                    height       : auto;
                    background   : transparent;
                    border       : none;
                    margin-bottom: 28px;
                    position     : relative;

                    label {
                        font-size : 15px;
                        color : #555555;
                        font-weight : 500;

                        @media(max-width: 575px) {
                            font-size : 14px;
                        }
                    }

                    input {
                        width           : 100%;
                        height          : 48px;
                        background-color: #ffffff;
                        font-size       : 14px;
                        padding         : 15px 20px;
                        color           : #182B49;
                        border          : 1px solid #dddddd;
                        border-radius : 5px;

                        &::placeholder {
                            font-size : 14px;
                            font-style: italic;
                            color     : #969696;

                            @media(max-width: 575px) {
                                font-size : 13px;
                            }
                        }

                        &:focus {
                            border-color : #11B67A;
                        }

                        @media(max-width: 575px) {
                            height : 40px;
                        }
                    }

                    span {
                        color      : #ff6f6f;
                        font-weight: 300;
                        position   : absolute;
                        bottom     : -20px;
                        left       : 0;
                        visibility : hidden;
                    }
                }

                p.form-control.success {
                    input {
                        border: 2px solid #11B67A;
                    }

                    &::before {
                        position   : absolute;
                        content    : "\f058";
                        font-family: "Line Awesome Free";
                        font-size  : 24px;
                        color      : #11B67A;
                        font-weight: 900;
                        top        : 34px;
                        right      : 10px;
                    }
                }

                p.form-control.error {
                    input {
                        border: 2px solid #ff6f6f;
                    }

                    &::before {
                        position   : absolute;
                        content    : "\f06a";
                        font-family: "Line Awesome Free";
                        font-size  : 24px;
                        color      : #ff6f6f;
                        font-weight: 900;
                        top        : 34px;
                        right      : 10px;
                    }
                }

                p.form-control.error {
                    span {
                        visibility: visible;
                    }
                }

                button {
                    font-size  : 16px;
                    color      : #fff;
                    background : linear-gradient(90deg, #11B67A 0%, #009444 100%);
                    width      : 100%;
                    height     : 48px;
                    font-weight: 500;
                    border     : none;
                    border-radius : 5px;
                    text-transform: uppercase;
                    margin-bottom : 20px;

                    &:hover {
                        background: linear-gradient(90deg, #009444 0%, #11B67A 100%);

                        i {
                            color: #ffffff;
                        }
                    }

                    @media(max-width: 575px) {
                        font-size : 15px;
                        height     : 40px;
                    }
                }
            }

            .have_account-btn {
                p {
                    font-size : 14px;
                    color     : #969696;
                    a {
                        font-size : 14px;
                        color : #11B67A;
                        font-weight : 500;
                        &:hover {
                            text-decoration : underline;
                        }
                    }
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0;
        }
    }
}