.course-tag {
    border : 1px solid #eeeeee;
    padding: 15px 20px 12px;
    border-radius : 5px;
    h5 {
        color : #182B49;
        font-weight : 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position : relative;
        &:before {
            position : absolute;
            content : "";
            background : #11B67A;
            width : 50px;
            height: 2px;
            bottom : 0;
            left : 0;
        }

        @media(max-width: 575px) {
            font-size  : 17px;
        }
    }
    .tag-box {
        a {
            font-size: 13px;
            color : #555555;
            border: 1px solid #dddddd;
            padding: 6px 8px 5px;
            margin: 0 8px 10px 0;
            display: inline-block;
            border-radius: 5px;
            &:hover {
                color : #ffffff;
                background : linear-gradient(90deg, #11B67A 0%, #009444 100%);
                border-color : #11B67A;
            }
        }
    }

    @media(max-width: 1199px) {
        padding: 12px 15px 6px;
    }

    @media(max-width: 575px) {
        margin-bottom : 30px;
    }
}